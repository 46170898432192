import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/store/store'
import { formatPrice } from 'utils/ruleForm'
import {
  requestGetNotification,
  requestLevelCategory,
  requestLevelList,
  requestSetLevelUser,
} from './Api/LevelApi'
import CatalogAboutUs from './components/CatalogAboutUs'
import QuestHall from './components/QuestHall'
import QuickAction from './components/QuickAction'
import SlickBanner from './components/SlickBanner'
import * as Styled from './styled'
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import { Col, Row } from 'antd'
import { InfoBlock } from 'common/components/InfoBlock'
import R from 'assets'
import styled from 'styled-components'
import { getListAdvertisement, getUserAnalytic } from 'services/api/CommonApi'
import './style.css'
import TextAnimate from './components/TextAnimate'
import { useTranslation } from 'react-i18next'
import { URL_IMAGE } from 'services/ApiService'
// import TawkTo from 'tawkto-react'

const BannerMiddle = styled.div`
  .banner-middle-1 {
    // height: 68px;
    margin-top: 6px;
    .banner-1 {
      flex: 1;
    }
    display: flex;
    width: 100%;

    div + div {
      padding-left: 5px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .banner-middle-2 {
    width: 100%;
    // height: 68px;
    margin-top: 6px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: 580px) {
    .banner-middle-1 {
      height: 68px;
    }
    .banner-middle-2 {
      height: 68px;
    }
  }
`

const HighlightedProducts = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;

  .product-card {
    width: calc(25% - 16px);
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    .product-info {
      padding: 16px;

      .product-name {
        font-size: 16px;
        font-weight: bold;
        margin-top: 8px;
        color: #333;
      }

      .product-price {
        font-size: 14px;
        color: #999;
        text-decoration: line-through;
        margin-top: 8px;
      }

      .product-price-discount {
        font-size: 18px;
        font-weight: bold;
        color: #e53935;
        margin-top: 4px;
      }

      .product-rating {
        margin-top: 8px;
        color: #fbc02d;
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 580px) {
    .product-card {
      width: calc(50% - 16px); /* Chiều rộng 50% khi màn hình nhỏ hơn 580px */
    }
  }
`

const initialProducts = [
  {
    image: R.images.sp1,
    name: 'Wireless Gaming Keyboard and Mouse Combo',
    Price: 'VNĐ 1,730,200',
    price_discount: 'VNĐ 1,156,200',
    star: 3,
  },
  {
    image: R.images.sp2,
    name: 'Breitling Women BTA7738711-G761SS',
    Price: 'VNĐ 83,475,000',
    price_discount: 'VNĐ 77,475,000',
    star: 4,
  },
  {
    image: R.images.sp3,
    name: 'Baboies 2 Tier Corner Shelf Kitchen Spice Rack',
    Price: 'VNĐ 483,000',
    price_discount: 'VNĐ 360,000',
    star: 5,
  },
  {
    image: R.images.sp4,
    name: 'Gigabyte Aorus 17 YE5-74PT544SH ',
    Price: 'VNĐ 68,256,000',
    price_discount: 'VNĐ 62,198,000',
    star: 5,
  },
  {
    image: R.images.sp5,
    name: 'Xiaomi Mi 11T Pro',
    Price: 'VNĐ 12,254,600',
    price_discount: 'VNĐ 10,254,600',
    star: 4,
  },
  {
    image: R.images.sp6,
    name: 'Camera EOS 2000D (Rebel T7) ',
    Price: 'VNĐ 11,450,000',
    price_discount: 'VNĐ 9,850,600',
    star: 4,
  },
  {
    image: R.images.sp7,
    name: 'Apple iPhone 14 Pro Max 256G',
    Price: 'VNĐ 26,850,600',
    price_discount: 'VNĐ 22,299,000',
    star: 5,
  },
  {
    image: R.images.sp8,
    name: 'Joe Rodeo Chelsea JCH4 Diamond Watch',
    Price: 'VNĐ 127,950,000',
    price_discount: 'VNĐ 104,825,000',
    star: 5,
  },
]

const HomePage: React.FC = () => {
  const [incomeList, setIncomeList] = useState<any>([])
  const [levelList, setLevelList] = useState<any>([])
  const [listAdvertisement, setListAdvertisement] = useState<any>([])
  const [currentLevelIdx, setCurrentLevelIdx] = useState<number>(0)
  const [data, setData] = useState<any>([])
  const [language, setLanguage] = useState<any>('')
  const [noti, setNoti] = useState<any>()
  const { t } = useTranslation()

  const [analyticDetail, setAnalyticDetail] = useState<any>()

  const { userInfo } = useAppSelector(state => state.AuthReducer)

  const dispatch = useAppDispatch()

  const requestSetLevel = async () => {
    try {
      const res = await requestSetLevelUser()
      if (res) getLevelList(res.data.level)
    } catch (error) {
      console.log('error')
    }
  }

  const requestListAdvertisement = async () => {
    try {
      const res = await getListAdvertisement()
      if (res) setListAdvertisement(res.data)
    } catch (error) {
      console.log('error')
    }
  }

  useEffect(() => {
    // var Tawk_API: any = Tawk_API || {},
    //   Tawk_LoadStart = new Date()
    // ;(function () {
    //   var s1 = document.createElement('script'),
    //     s0 = document.getElementsByTagName('script')[0]
    //   s1.async = true
    //   s1.src = 'https://embed.tawk.to/634e3ef7daff0e1306d28b5e/1gfl3ogh9'
    //   s1.charset = 'UTF-8'
    //   s1.setAttribute('crossorigin', '*')
    //   s0.parentNode!.insertBefore(s1, s0)
    // })()
    // var tawk = new TawkTo('634e3ef7daff0e1306d28b5e', '1gfkqbttr')
    requestSetLevel()
    getRandomList()
    setInterval(() => {
      getRandomList()
    }, 5000)
    requestListAdvertisement()
  }, [])

  useEffect(() => {
    getLevelCategory()
  }, [levelList])

  useEffect(() => {
    getNotification()
  }, [])

  const getLevelList = async (level: any) => {
    try {
      const resData = (await requestLevelList()).data
      const ownLevel = level

      const currentLevelData = resData.find((item: any) => item.key == ownLevel)
      let currentLvIdx =
        resData.indexOf(currentLevelData) == -1
          ? 0
          : resData.indexOf(currentLevelData)

      setLevelList([...resData])
      setCurrentLevelIdx(currentLvIdx)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getLevelCategory = async () => {
    try {
      let full_data = [...levelList]
      levelList?.forEach(async (item: any, index: number) => {
        const res = await requestLevelCategory(item.key)
        full_data[index].category = res.data.map((item: any) => {
          return item.name
        })
      })

      setData(full_data)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }
  const getNotification = async () => {
    try {
      const res = await requestGetNotification()
      if (res.data) {
        let language = await localStorage.getItem('language')
        setLanguage(language)
        setTimeout(() => {
          setNoti(res.data[0])
        }, 150)
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getRandomList = () => {
    let list_random = []
    for (var i = 0; i < 5; i++) {
      let text = randomIncome()
      list_random.push({
        name: text,
        income: 'VNĐ ' + formatPrice(Math.floor(Math.random() * 9999) + 99),
        date: moment().format('MM-DD'),
      })
    }
    setIncomeList(list_random)
  }

  const randomIncome = () => {
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    var text = ''
    text =
      possible.charAt(Math.floor(Math.random() * possible.length)) +
      possible.charAt(Math.floor(Math.random() * possible.length)) +
      '*****' +
      possible.charAt(Math.floor(Math.random() * possible.length)) +
      possible.charAt(Math.floor(Math.random() * possible.length))
    return text
  }

  useEffect(() => {
    ;(async () => {
      const analyticData = await getUserAnalytic()
      console.log(
        '👿 ~ file: HomePage.tsx ~ line 160 ~ ; ~ analyticData.data',
        analyticData.data
      )
      setAnalyticDetail(analyticData.data)
    })()
  }, [])

  const [listProducts, setListProducts] = useState(initialProducts)

  useEffect(() => {
    // Randomize products and select only 4
    const shuffledProducts = [...listProducts]
      .sort(() => Math.random() - 0.5)
      .slice(0, 4) // Lấy 4 sản phẩm đầu tiên sau khi đã sắp xếp ngẫu nhiên
    setListProducts(shuffledProducts)
  }, [])
  return (
    <div>
      <SlickBanner />
      <TextAnimate
        content={language == 'vi' ? noti?.content : noti?.content_english}
      />
      <QuickAction />
      <Row justify="center" gutter={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
        <Col xs={12} sm={12} lg={12} style={{ marginTop: '12px' }}>
          <InfoBlock
            title1={t('account_balance')}
            value1={`VNĐ ${formatPrice(
              Number(Number.parseFloat(userInfo?.balance).toFixed(2))
            )}`}
            title2={t('income_today')}
            value2={`VNĐ ${formatPrice(
              Number(
                Number.parseFloat(
                  analyticDetail?.total_commission_today
                ).toFixed(2)
              )
            )}`}
          />
        </Col>

        <Col xs={12} sm={12} lg={12} style={{ marginTop: '12px' }}>
          <InfoBlock
            type="featured"
            title1={t('processing_balance')}
            value1={`VNĐ ${formatPrice(
              Number(Number.parseFloat(userInfo?.keep_money).toFixed(2))
            )}`}
            title2={t('income_yesterday')}
            value2={`VNĐ ${formatPrice(
              Number(
                Number.parseFloat(analyticDetail?.total_commission).toFixed(2)
              )
            )}`}
          />
        </Col>
      </Row>
      {/* <QuestHall levelList={data} currentLevelIndex={currentLevelIdx} /> */}
      {/* <CatalogAboutUs /> */}
      <BannerMiddle>
        <div className="banner-middle-1">
          <div className="banner-1">
            <img
              alt="banner"
              src={
                URL_IMAGE +
                (language == 'vi'
                  ? listAdvertisement[0]?.image
                  : listAdvertisement[0]?.image_english)
              }
              crossOrigin="anonymous"
            />
          </div>

          <div className="banner-1">
            <img
              alt="banner"
              src={
                URL_IMAGE +
                (language == 'vi'
                  ? listAdvertisement[1]?.image
                  : listAdvertisement[1]?.image_english)
              }
              crossOrigin="anonymous"
            />
          </div>
        </div>

        <div className="banner-middle-2">
          <img
            alt="banner"
            src={
              URL_IMAGE +
              (language == 'vi'
                ? listAdvertisement[2]?.image
                : listAdvertisement[2]?.image_english)
            }
            crossOrigin="anonymous"
          />
        </div>
      </BannerMiddle>

      <HighlightedProducts>
        {listProducts.map((product, index) => (
          <div key={index} className="product-card">
            <img src={product.image} alt={product.name} />
            <div className="product-info">
              <div className="product-name">{product.name}</div>
              <div className="product-price">{product.Price}</div>
              <div className="product-price-discount">
                {product.price_discount}
              </div>
              <div className="product-rating">
                {'⭐'.repeat(Math.floor(product.star))}
                {product.star % 1 !== 0 && '⭐'}
              </div>
            </div>
          </div>
        ))}
      </HighlightedProducts>
      <TawkMessengerReact
        propertyId="634e3ef7daff0e1306d28b5e"
        widgetId="1gfl3ogh9"
      />
    </div>
  )
}
export default HomePage
