import { FileDoneOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { Layout, message } from 'antd'
import R from 'assets'
import i18n from 'assets/i18n/translation'
import { ADMIN_ROUTER_PATH, SESSION_KEY } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import Cookie from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineLock, AiOutlineLogout } from 'react-icons/ai'
import { BiSupport } from 'react-icons/bi'
import {
  BsCardChecklist,
  BsFillDiagram3Fill,
  BsLock,
  BsReply,
} from 'react-icons/bs'
import { FaAddressCard, FaMoneyBillAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'redux/store/store'
import styled from 'styled-components'
import history from 'utils/history'
import { requestLevelList, requestSetLevelUser } from '../home/Api/LevelApi'

const { Content } = Layout

// const HeadTitle = ({ title }: { title: string }) => {
//   return (
//     <div
//       style={{
//         backgroundColor: 'lightgray',
//         fontWeight: 'bold',
//         padding: 10,
//         marginTop: 10,
//         fontSize: 18,
//       }}
//     >
//       {title}
//     </div>
//   )
// }

const BlockOption = styled.div`
  background-color: #191e24;
  padding: 5px;
  border-radius: 3px;
  .item {
    border-bottom: 1px solid #27282d;
    :last-child {
      border: none;
    }
  }
`

const OptionSelect = ({
  icon,
  title,
  to,
}: {
  icon: unknown
  title: string
  to: string
}) => {
  return (
    <div
      className="item"
      style={{
        padding: 5,
        paddingLeft: 12,
        fontSize: 14,
      }}
    >
      <Link
        to={to}
        style={{
          color: 'white',
          alignItems: 'center',
          display: 'flex',
          gap: 6,
        }}
      >
        {icon}
        <div style={{ width: 5 }}></div>
        {title}
      </Link>
    </div>
  )
}

const ProfilePage: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const dispatch = useDispatch()
  const [dataLevel, setDataLevel] = useState<any>({})
  const { t } = useTranslation()
  const [language, setLanguage] = useState<any>('')

  const getLevelList = async (level: any) => {
    try {
      const res = await requestLevelList()

      const ownLevel = userInfo?.level

      let currentLevelData = res.data.find((item: any) => item.key == level)
      currentLevelData = currentLevelData ? currentLevelData : res.data[0]
      console.log('currentLevelData', currentLevelData)
      console.log('level', level)

      setDataLevel(currentLevelData)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const requestSetLevel = async () => {
    try {
      const res = await requestSetLevelUser()
      if (res) {
        let language = await localStorage.getItem('language')
        setLanguage(language)
        dispatch(getUserInfoAction())
        getLevelList(res.data.level)
      }
    } catch (error) {
      console.log('error')
    }
  }

  useEffect(() => {
    requestSetLevel()
  }, [])

  return (
    <Content
      style={{
        padding: '0 10px',
        margin: '10px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
      }}
    >
      {/* <Row justify="center">
        <Col span="12">
          <Row align="middle">
            <Col>
              <Image
                style={{ borderRadius: 5 }}
                width={70}
                height={70}
                src={R.images.avt_placeholder}
              />
            </Col>
            <Col style={{ marginLeft: 10 }}>
              <Row style={{ height: '100%' }} align="middle">
                <Col>
                  <Row>
                    <b style={{ fontSize: 20 }}>{userInfo?.name}</b>
                  </Row>
                  <Row>
                    <b style={{ fontSize: 16 }}>
                      Số điện thoại: {formatSensitiveText(userInfo?.phone, 4)}
                    </b>
                  </Row>
                  <Row>
                    <b style={{ fontSize: 16 }}>
                      Số dư tài khoản:{' '}
                      <b style={{ color: 'green' }}>{`$${
                        formatPrice(userInfo?.balance) || 0
                      }`}</b>
                    </b>
                  </Row>
                  <Row>
                    <b style={{ fontSize: 14, color: 'grey' }}>
                      Mã mời: {userInfo?.reference_code || userInfo?.phone}
                    </b>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span="12">
          <Row
            justify="end"
            align="middle"
            style={{ height: '100%', padding: 10 }}
          >
            <Col>
              <Button
                style={{ fontWeight: '700' }}
                onClick={() => {
                  history.push(ADMIN_ROUTER_PATH.SUPPORT)
                }}
              >
                Nạp tiền
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  dispatch(getUserInfoAction())

                  if (userInfo?.balance == 0) {
                    Swal.fire({
                      title: 'Thông báo',
                      text: 'Số dư tài khoản không đủ để thực hiện thao tác này. Bạn có muốn nạp tiền?',
                      icon: 'error',
                      showCancelButton: true,
                    }).then((result: any) => {
                      if (result.isConfirmed) {
                        history.push(ADMIN_ROUTER_PATH.RECHARGE_MONEY)
                      }
                    })
                  } else {
                    if (userInfo.bank) {
                      history.push(ADMIN_ROUTER_PATH.WITHDRAW_MONEY)
                    } else {
                      message.error('Vui lòng cập nhập thông tin thẻ ngân hàng')
                      history.push(ADMIN_ROUTER_PATH.BANK_CARD)
                    }
                  }
                }}
                style={{ fontWeight: '700' }}
              >
                Rút tiền
              </Button>
            </Col>
          </Row>
        </Col>
      </Row> */}
      <div
        style={{
          backgroundColor: '#303845',
          display: 'flex',
          justifyContent: 'space-around',
          padding: '4px 0',
          fontSize: 15,
        }}
      >
        <div style={{ color: '#808895' }}>{userInfo?.name}</div>
        <div style={{ fontWeight: 'bold' }}>
          {language == 'vi' ? dataLevel?.name : dataLevel?.name_english}
        </div>
        <div style={{ display: 'flex', gap: '5px' }}>
          <div style={{ color: '#808895' }}>ID: </div>
          <div> {userInfo?.reference_code || userInfo?.phone}</div>
        </div>
      </div>
      {/* <HeadTitle title="Thông tin cá nhân" /> */}
      {/* <OptionSelect
        to={ADMIN_ROUTER_PATH.DELIVERY_ADDRESS}
        title="Thông tin cá nhân"
        icon={<BiLocationPlus />}
      /> */}
      <BlockOption>
        <OptionSelect
          to={ADMIN_ROUTER_PATH.BANK_CARD}
          title={t('card')}
          icon={<img style={styleIcon} src={R.icon.taikhoannganhang} />}
        />
        <OptionSelect
          to={ADMIN_ROUTER_PATH.MEMBER_RANK}
          title={t('membership_level')}
          icon={<BsFillDiagram3Fill />}
        />
        <OptionSelect
          to={ADMIN_ROUTER_PATH.DELIVERY_ADDRESS}
          title={t('address_reciver')}
          icon={<img style={styleIcon} src={R.icon.diachicuchu} />}
        />
      </BlockOption>
      {/* <HeadTitle title="Lịch sử đơn hàng" /> */}
      <BlockOption>
        <OptionSelect
          to={ADMIN_ROUTER_PATH.HISTORY}
          title={t('history_find_order')}
          icon={<img style={styleIcon} src={R.icon.lichsudonhang} />}
        />
        <OptionSelect
          to={ADMIN_ROUTER_PATH.ORDER}
          title={t('start_find_order')}
          icon={<ShoppingCartOutlined />}
        />
        <OptionSelect
          to={`${ADMIN_ROUTER_PATH.MY_GROUP}?tabs=level_1`}
          title={t('my_room')}
          icon={<BsFillDiagram3Fill />}
        />
      </BlockOption>
      {/* <HeadTitle title="Tài khoản" /> */}
      <BlockOption>
        <OptionSelect
          to={ADMIN_ROUTER_PATH.HISTORY_TRANSACTION}
          title={t('history_transaction')}
          icon={<FaMoneyBillAlt />}
        />
        <OptionSelect
          to={ADMIN_ROUTER_PATH.CHANGE_PASS}
          title={t('password_account')}
          icon={<img style={styleIcon} src={R.icon.matkhaucanhan} />}
        />
        <OptionSelect
          to={ADMIN_ROUTER_PATH.DEPOSIT_PASS}
          title={t('capital_password')}
          icon={<BsLock />}
        />
      </BlockOption>
      {/* <HeadTitle title="Liên hệ chăm sóc khác hàng" /> */}
      <BlockOption>
        <OptionSelect
          to={ADMIN_ROUTER_PATH.SUPPORT}
          title={t('customer_care_center')}
          icon={<img style={styleIcon} src={R.icon.cskh} />}
        />
        <OptionSelect
          to={ADMIN_ROUTER_PATH.SUPPORT}
          title={t('Feedback')}
          icon={<BsReply />}
        />
      </BlockOption>
      {/* <Divider style={{ margin: 5 }} /> */}
      <button
        style={{ height: 30 }}
        color="red"
        onClick={() => {
          console.log(i18n.language)
          if (i18n.language === 'vi') {
            i18n.changeLanguage('en')
          } else {
            i18n.changeLanguage('vi')
          }
          localStorage.setItem('language', i18n.language)
        }}
        children={
          <a>
            {i18n.language === 'vi' ? 'Thay đổi ngôn ngữ' : 'Change language'}
          </a>
        }
      />
      <button
        style={{
          padding: 2,
          borderRadius: 3,
          backgroundColor: '#303845',
          border: 'none',
        }}
        onClick={() => {
          const refWindow: any = window
          if (refWindow.$chatwoot) refWindow.$chatwoot.reset()
          Cookie.remove(SESSION_KEY.SESSION)
          message.success(t('logout'))
          history.replace('/')
        }}
      >
        <a
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 6,
            color: 'red',
            fontSize: 18,
          }}
        >
          <AiOutlineLogout /> <b>{t('logout')}</b>
        </a>
      </button>
    </Content>
  )
}

const styleIcon = { width: '14px', height: '14px' }

export default ProfilePage
function getUserInfor(): any {
  throw new Error('Function not implemented.')
}
