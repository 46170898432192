import React from 'react'
import styled, { ThemedStyledFunction } from 'styled-components'

// const withProps = <U>() => <P, T, O>(fn: ThemedStyledFunction<P, T, O>) =>
//     fn as ThemedStyledFunction<P & U, T, O & U>

interface IProps {
  title1: string
  value1: string
  title2: string
  value2: string
  type?: string
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #2a313c;
  border-radius: 6px;
  padding: 6px 0px;
  // padding: 12px 32px;
  @media only screen and (max-width: 480px) {
    // padding: 4px 6px;
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: rgba(126, 133, 147);
  line-height: 20px;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`

const Value = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #eaebee;
  line-height: 22px;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 18px;
  }
`

function InfoBlock(props: IProps) {
  const { title1, value1, title2, value2, type } = props
  return (
    <Wrapper>
      <Title>{title1}</Title>

      {type === 'featured' ? (
        <Value style={{ color: 'rgba(183,62,82)' }}>{value1}</Value>
      ) : (
        <Value style={{ color: 'rgba(231,233,236)' }}>{value1}</Value>
      )}

      <Title>{title2}</Title>

      {type === 'featured' ? (
        <Value style={{ color: 'rgba(160,73,211)' }}>{value2}</Value>
      ) : (
        <Value style={{ color: 'rgba(82,164,120)' }}>{value2}</Value>
      )}
    </Wrapper>
  )
}

export default InfoBlock
