import { Avatar, Col, Image, Menu, Row } from 'antd'
import R from 'assets'
import React from 'react'
import { useAppSelector } from 'redux/store/store'
import { formatPrice } from 'utils/ruleForm'
import * as Style from './styled'

const HeaderContainer: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.AuthReducer)

  // const menu = (
  //   <Menu
  //     style={{ width: 400, minHeight: 150 }}
  //     // items={[
  //     //   {
  //     //     key: '1',
  //     //     label: (
  //     //       <a
  //     //         target="_blank"
  //     //         rel="noopener noreferrer"
  //     //         href="https://www.aliyun.com"
  //     //       >
  //     //         2nd menu item
  //     //       </a>
  //     //     ),
  //     //   },
  //     // ]}
  //   >
  //     <Menu.Item key={0}>
  //       <Row
  //         style={{
  //           background: '#ededed',
  //           padding: 7,
  //           borderRadius: 5,
  //         }}
  //         align={'middle'}
  //       >
  //         <Image preview={false} width={50} height={50} src={R.images.notify} />
  //         <Col style={{ marginLeft: 10 }}>
  //           <div style={{ fontWeight: 600, fontSize: 16 }}>Thông báo</div>
  //           <div>Chào mừng bạn đến với tiktokshop</div>
  //         </Col>
  //       </Row>
  //     </Menu.Item>
  //   </Menu>
  // )

  return (
    <Style.HeaderBlock>
      <div
        style={{ display: 'flex', alignItems: 'center', gap: '12px', flex: 1 }}
      >
        <Avatar src="https://joeschmoe.io/api/v1/random" />
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            gap: '8px',
            backgroundColor: '#2A313C',
            padding: '0 20px ',
            borderRadius: '20px',
          }}
        >
          <i
            style={{ fontSize: 20, marginLeft: -5 }}
            className="fa-solid fa-wallet"
          ></i>
          <div style={{ flex: 1 }}>
            <div style={{ color: '#7e8592', fontSize: 12 }}>
              {userInfo?.name}
            </div>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: 13,
                marginTop: -5,
                // display: 'flex',
                // justifyContent: 'center',
              }}
            >
              VNĐ {formatPrice(Math.round(userInfo?.balance)) || 0}
            </div>
          </div>
        </div>
      </div>
      <div>
        <img
          alt="logo"
          src={R.images.logo_web}
          style={{
            width: '110px',
          }}
        />
      </div>
    </Style.HeaderBlock>
  )
}
const styleIcon = { fontSize: '20px' }

export { HeaderContainer }
