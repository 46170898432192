import React from 'react'
import './App.css'
import 'antd/dist/antd.css'
import GlobalStyles from 'global-styles'
import AppNavigator from 'navigation'
import './global-styles.css'
import i18n from 'assets/i18n/translation'

function App() {
  const checkChangeLanguage = async () => {
    let lang = (await localStorage.getItem('language')) || 'en'
    i18n.changeLanguage(lang)
  }

  React.useEffect(() => {
    checkChangeLanguage()
  }, [])

  return (
    <React.Fragment>
      <AppNavigator />
      <GlobalStyles />
    </React.Fragment>
  )
}

export default App
