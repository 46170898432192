const icon = {
  cskh: require('./cskh.png'),
  naptien: require('./naptien.png'),
  ruttien: require('./ruttien.png'),
  thongbao: require('./thongbao.png'),
  dautu: require('./dautu.png'),
  trangchu: require('./trangchu.png'),
  hethong: require('./hethong.png'),
  giaodich: require('./giaodich.png'),
  lichsu: require('./lichsu.png'),
  taikhoan: require('./taikhoan.png'),
  taikhoannganhang: require('./taikhoannganhang.png'),
  diachicuchu: require('./diachicuchu.png'),
  lichsudonhang: require('./lichsudonhang.png'),
  matkhaucanhan: require('./matkhaucanhan.png'),
  matkhaudangnhap: require('./matkhaudangnhap.png'),
}
export default icon
