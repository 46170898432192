const images = {
  img_background: require('./img_background.jpg').default,
  logo_web: require('./logo_web.png'),
  banner1: require('./banner1.png'),
  banner2: require('./banner2.png'),
  banner3: require('./banner3.png'),
  banner11: require('./banner11.png'),
  banner22: require('./banner22.png'),
  banner33: require('./banner33.png'),
  banner4: require('./banner4.png'),
  banner5: require('./banner5.png'),
  img_countdown: require('./img_countdown.gif'),
  bg: require('./bg.jpg'),
  logo: require('./logo.png'),
  avt_placeholder: require('./avt_placeholder.png'),
  notify: require('./notify.jpg'),
  visa_img: require('./visa_img.gif'),
  logo_tiktok_shop: require('./logo_tiktok_shop.png'),
  city: require('./city.png'),
  sp1: require('./sp1.jpg'),
  sp2: require('./sp2.jpg'),
  sp3: require('./sp3.jpg'),
  sp4: require('./sp4.jpg'),
  sp5: require('./sp5.jpg'),
  sp6: require('./sp6.jpg'),
  sp7: require('./sp7.jpg'),
  sp8: require('./sp8.jpg'),
}
export default images
