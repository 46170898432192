import { FacebookOutlined, GoogleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Divider, Form, Input } from 'antd'
// import LoadingProgress from "common/components/LoadingProgress";
import { SESSION_KEY } from 'common/config'
// import { getUserInfoAction } from 'features/auth/AuthSlice'
import Cookie from 'js-cookie'
import { useState } from 'react'
import R from 'assets'
import { requestLogin } from './AuthApi'
import './authStyle.css'
import { useDispatch } from 'react-redux'
import { getUserInfoAction } from './AuthSlice'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const FormStyled = styled(Form)`
  .ant-form-item-label > label {
    color: #3b444b;
  }
  z-index: 1;
`
const BGImageStyled = styled.div`
  background-image: url(${R.images.city});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  top: 0;
  bottom: 0;
  position: absolute;
  right: 0;
  left: 0;
`

const WrapperStyled = styled.div`
  width: 500px;
  padding: 20px 16px;
  display: flex;
  flex-flow: column;
  position: relative;
`

const TextHederStyled = styled.p`
  color: #636363;
  position: absolute;
  top: 5px;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-family: Verdana, sans-serif;
  font-weight: 500;
  font-size: x-large;
`
const HeaderStyled = styled.div`
  background-color: #fffbfe;
  height: 50px;
  z-index: 1;
  margin-top: -20px;
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
  vertical-align: middle;
  padding: 8px;
  opacity: 0.2;
`
const IconButton = styled(Button)`
  width: 45px;
  height: 45px;
  background-color: var(--gray-color);
  color: white;

  border-radius: 100px;
  margin-left: 10px;
  margin-right: 10px;
  :hover {
    border: none;
    background-color: #ffffff;
    color: black;
  }

  :focus {
    border: none;
    background-color: var(--gray-color);
    color: white;
  }

  :active {
    border: none;
    background-color: var(--gray-color);
    color: white;
  }
`

const TitleStyled = styled.h3`
  text-align: center;
  margin: 20px 0px;
  color: white;
  font-weight: 600;
`

const LogoStyled = styled.img`
  width: 20%;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`

const DividerStyled = styled(Divider)`
  &&& {
    color: #555555;
    border-top-color: #818996;
    font-size: 20px;
  }
`

const SummitButton = styled(Button)`
  width: 100%;
  height: 45px;
  background-color: var(--gray-color);
  color: white;

  border: 1px solid #f6d658;
  border-radius: 6px;

  :hover {
    border: none;
    background-color: #ffffff;
    color: black;
  }

  :focus {
    border: none;
    background-color: var(--gray-color);
    color: white;
  }

  :active {
    border: none;
    background-color: var(--gray-color);
    color: white;
  }
`
const CheckboxStyled = styled(Checkbox)`
  color: #3b444b;
  .text-high-light {
    color: #eaebee;
  }
  margin-bottom: 12px;
`

function Login(props: any) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)

  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      const resLogin = await requestLogin({
        identifier: values.phone,
        password: values.password,
      })
      Cookie.set(SESSION_KEY.SESSION, resLogin.data.token, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      })
      dispatch(getUserInfoAction())
      message.success(t('login_success'))
      setTimeout(() => {
        history.replace('/')
      }, 1000)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <div className="login">
      <WrapperStyled>
        <HeaderStyled></HeaderStyled>

        <TextHederStyled>TikTok Shop</TextHederStyled>
        <BGImageStyled>
          <div
            className="overlay"
            style={{ background: '#fffbfe', opacity: 0.65, height: '100%' }}
          />
        </BGImageStyled>
        <LogoStyled alt="logo" src={R.images.logo_tiktok_shop} />

        {/* <TitleStyled>{t('login')}</TitleStyled> */}

        <FormStyled
          form={form}
          layout={'vertical'}
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
            phone: null,
            password: null,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="phone"
            label={t('phone')}
            rules={[{ required: true, message: t('not_empty') }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label={t('pass')}
            name="password"
            rules={[
              { required: true, message: t('not_empty') },
              { min: 6, message: t('validate_character') },
            ]}
          >
            <Input size="large" type="password" />
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CheckboxStyled>{t('remember_password')}</CheckboxStyled>
            <Button
              type="text"
              href="https://s.net.vn/IgYr"
              style={{
                color: '#7CC1D7',
                fontSize: '16px',
                fontWeight: 800,
                marginTop: -5,
              }}
            >
              {t('forgot_pass')}
            </Button>
          </div>

          <Form.Item
            style={{
              textAlign: 'center',
            }}
          >
            <SummitButton
              type="primary"
              htmlType="submit"
              // className="login-form-button"
              style={{ width: '100%', fontWeight: 700 }}
            >
              {t('login')}
            </SummitButton>
          </Form.Item>
        </FormStyled>

        <div
          style={{
            zIndex: 1,
            color: 'black',
            marginTop: '-15px',
          }}
        >
          <DividerStyled plain>{t('or_login_with')}</DividerStyled>
        </div>
        <div
          style={{
            zIndex: 1,
            color: 'black',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <IconButton icon={<GoogleOutlined />} />
          <IconButton icon={<FacebookOutlined />} />
        </div>
        <div
          style={{
            zIndex: 1,
            color: '#636363',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 15,
          }}
        >
          <p>{t('you_dont_have_account')}</p>
          <Button
            type="text"
            htmlType="submit"
            onClick={() => history.push('/register')}
            style={{
              color: '#7CC1D7',
              fontSize: '15px',
              fontWeight: 800,
              marginTop: -5,
            }}
          >
            {t('register_now')}
          </Button>
        </div>
      </WrapperStyled>
    </div>
  )
}

export default Login
