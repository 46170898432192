import { Row } from 'antd'
import styled from 'styled-components'

export const HeaderBlock = styled.div`
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 18px;
`

export const ItemBlock = styled.span`
  margin-left: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`

export const FooterBlock = styled(Row)`
  color: white;
  background-color: '#0C131D';
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: -4px;
  // height: 42px;

  // border-top: 1px solid rgba(255, 255, 255, 0.3);

  span {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    :hover {
      cursor: pointer;
    }
    p {
      margin: 0.3rem 0;
    }
    .circle-item {
      aspect-ratio: 1 / 1;
      /* background-color: #febd69; */
      border-radius: 50%;
      padding: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* border: 2px #febd69 solid; */
    }
  }
  .main-footer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu-active {
    color: rgba(245, 197, 86);
  }
  @media only screen and (max-width: 576px) {
    font-size: 12px;
  }
  .menu-active[id='order'] {
    /* color: var(--primary-color); */
  }
`
