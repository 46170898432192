import { Button, Col, Image, Row } from 'antd'
import R from 'assets'
import { BonusIcon, CustomerCareIcon, TaskIcon } from 'common/components/Icons'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'redux/store/store'
import { getListLevel } from 'services/api/CommonApi'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import { requestSetLevelUser } from '../home/Api/LevelApi'
import { LIST_RANK } from './mooks'
import * as S from './styled'

const IconStyle = { fontSize: '38px' }
const MemberRankPage: React.FC = () => {
  const [listRank, setListRank] = useState<Array<any>>([])
  const [currentLevelIdx, setCurrentLevelIdx] = useState<number>(0)
  const [language, setLanguage] = useState<any>('')
  const { t } = useTranslation()

  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const dispatch = useAppDispatch()

  const getData = async (level: any) => {
    try {
      const resData = (await getListLevel()).data
      const ownLevel = level

      const currentLevelData = resData.find((item: any) => item.key == ownLevel)
      let currentLvIdx =
        resData.indexOf(currentLevelData) == -1
          ? 0
          : resData.indexOf(currentLevelData)

      setListRank(resData)
      setCurrentLevelIdx(currentLvIdx)
    } catch (err) {}
  }

  const requestSetLevel = async () => {
    try {
      const res = await requestSetLevelUser()
      if (res) {
        let language = await localStorage.getItem('language')
        setLanguage(language)
        getData(res.data.level)
      }
    } catch (error) {
      console.log('error')
    }
  }

  useEffect(() => {
    requestSetLevel()

    // dispatch(getUserInfoAction())
  }, [])

  return (
    <S.Container>
      <S.Title>{t('membership_upgrade')}</S.Title>
      <Row style={{ marginTop: '1rem' }}>
        <Col>
          <Image
            style={{ borderRadius: 5 }}
            width={70}
            height={70}
            src={R.images.avt_placeholder}
          />
        </Col>
        <Col style={{ marginLeft: 10 }}>
          <Row style={{ height: '100%' }} align="middle">
            <Col>
              <Row>
                <b style={{ fontSize: 20 }}>
                  {t('my_rank')}:{' '}
                  {language == 'vi'
                    ? listRank[currentLevelIdx]?.name
                    : listRank[currentLevelIdx]?.name_english}
                </b>
              </Row>
              <Row>
                <b style={{ fontSize: 14, color: 'grey' }}>
                  {t('order_on_day')}：
                  {listRank[currentLevelIdx]?.order_quantity_per_day} (
                  {t('order')})
                </b>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}
      >
        <S.ContainerOption>
          <S.OptionItem span={8}>
            <BonusIcon style={IconStyle} />
            {t('more_comission')}
          </S.OptionItem>
          <S.OptionItem span={8}>
            <TaskIcon style={IconStyle} />
            {t('more_mission')}
          </S.OptionItem>
          <S.OptionItem span={8}>
            <CustomerCareIcon style={IconStyle} />
            {t('customer_care_private')}
          </S.OptionItem>
        </S.ContainerOption>
      </div>
      <div style={{ textAlign: 'center' }}>{t('note_upgrade_level')}</div>
      <Row style={{ marginTop: '2rem' }}>
        {listRank.map((item, index) => {
          const isCurrent = index <= currentLevelIdx
          const isUpgradeable = index == currentLevelIdx + 1
          const isInvalid = index > currentLevelIdx && !isUpgradeable

          return (
            <S.OptionRankMember xs={24} md={12}>
              <S.OptionBlock>
                <Row align={'middle'} justify={'space-between'}>
                  <div className="title-rank">
                    {language == 'vi' ? item?.name : item.name_english}
                  </div>
                  {isCurrent && (
                    <div style={{ color: 'green' }} className="title-rank">
                      {t('upgraded')}
                    </div>
                  )}
                  {isUpgradeable && (
                    <Button
                      onClick={() => {
                        history.push(ADMIN_ROUTER_PATH.SUPPORT)
                        // history.push(
                        //   ADMIN_ROUTER_PATH.RECHARGE_MONEY +
                        //     `?level=${item?.key}`
                        // )
                      }}
                    >
                      {t('upgrade_now')}
                    </Button>
                  )}
                  {isInvalid && (
                    <div style={{ color: 'red' }}>
                      {t('request_complete_level')}{' '}
                      {language == 'vi'
                        ? listRank[index - 1]?.name
                        : listRank[index - 1]?.name_english}
                    </div>
                  )}
                </Row>
                <div className="money">{formatPrice(item?.price)} VNĐ</div>
                <hr style={{ borderTop: '1px solid #cccccc' }}></hr>
                <div className="content">
                  {t('number_mission')} {item?.order_quantity_per_day}{' '}
                  {t('order')}/{t('day')}
                </div>
                <div className="content">
                  {t('ratio_comission')} {item?.commission_percent}
                  {t('order_value')}
                </div>
                <div className="content">
                  {t('number_of_withdrawal_day')}{' '}
                  {item?.number_of_cash_out_per_day} {t('turn')}
                </div>
              </S.OptionBlock>
            </S.OptionRankMember>
          )
        })}
      </Row>
    </S.Container>
  )
}
export default MemberRankPage
