import { Row, Col } from 'antd'
import styled from 'styled-components'

export const ContainerHistory = styled.div`
  margin: 1rem 0;
`
export const TopTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  color: white;
  .title {
    font-size: 20px;
  }
`
export const ContainerTabs = styled.div`
  color: white;
  display: flex;
  justify-content: center;
`

export const HeaderTabs = styled(Row)`
  width: 100%;
  background-color: #394354;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  .title-tabs {
    padding: 4px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .title-tabs.active {
    color: var(--second-color);
    font-weight: 600;
  }
`

export const Container = styled.div`
  height: 100%;
  padding: 1rem 0;
  margin-bottom: 80px;
`

export const Title = styled.span`
  font-size: 20px;
`

export const ColStyled = styled(Col)`
  font-size: 16px;
  padding: 0 0 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;

  @media only screen and (min-width: 768px) {
    border-radius: 12px;
  }
`

export const Empty = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 1rem;
`

export const ItemWrapper = styled.div`
  background: white;
  padding: 5px 20px;
  box-shadow: rgb(17 17 26 / 10%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 8px 24px,
    rgb(17 17 26 / 10%) 0px 16px 48px;
  margin-bottom: 10px;
`

export const ContentTab = styled.div`
  padding: 1rem 0.5rem;
`

export const OrderBlock = styled(Col)`
  border: 1px solid #474c58;
  margin-bottom: 0.5rem;
  position: relative;
  cursor: pointer;
  display: flex;
  color: white;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: #474c58;
  }
  .right {
    flex: 2;
    padding: 14px 16px;
    .label {
      color: #828a94;
    }
    .name-product {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 6px;

      /* giới hạn số dòng text */
      display: -webkit-box;
      max-height: 40px;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 2;
      line-height: 20px;
    }
    .time {
      display: flex;
      gap: 15px;
    }
    .price-block {
      display: flex;
      gap: 15px;
    }
    .price-back {
      display: flex;
      gap: 15px;
    }
    .bottom {
      border-top: 1px solid #363841;
      padding-top: 8px;
      margin-top: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media only screen and (max-width: 420px) {
        flex-direction: column;
      }
      .bot-left {
        display: flex;
        gap: 5px;
      }
    }
  }
`
