import React from 'react'
import * as Styled from './styled'
import { MdSupportAgent } from 'react-icons/md'
import BlueIconComp from 'common/components/Icons/BlueIconComp'
import { useAppSelector } from 'redux/store/store'
import { useTranslation } from 'react-i18next'
import i18n from 'assets/i18n/translation'
// import { ChatwootWidget } from 'features/ChatwootWidget'

const styleIcon = { fontSize: '35px' }

const SupportPage: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const { t } = useTranslation()
  // const jsonString = `{ "intro_tesco": "${t('intro_tesco')}" }`
  // const jsonObject = JSON.parse(jsonString)
  // const introTesco = jsonObject.intro_tesco
  // console.log({ introTesco })

  // React.useEffect(() => {
  //   const refWindow: any = window
  //   if (refWindow?.$chatwoot) {
  //     refWindow.$chatwoot.toggle('open') // To open widget
  //     // refWindow.$chatwoot.toggleBubbleVisibility('show')

  //     refWindow.$chatwoot.setUser(userInfo?._id, {
  //       email: `${userInfo?.phone}@gmail.com`,
  //       name: userInfo?.name,
  //       avatar_url: userInfo.avatar,
  //       phone_number: userInfo?.phone,
  //     })
  //   } else {
  //     window.addEventListener('chatwoot:ready', function () {
  //       refWindow.$chatwoot?.toggle('open') // To open widget
  //     })
  //   }
  //   return () => {
  //     refWindow.$chatwoot?.toggle('close') // To close widget
  //     // refWindow.$chatwoot.toggleBubbleVisibility('hide')
  //   }
  // }, [])

  return (
    <Styled.Container>
      <Styled.WarpBox>
        <Styled.Block>
          <Styled.Title>
            <BlueIconComp icon={<MdSupportAgent style={styleIcon} />} />
            {t('service_care_customer')}
          </Styled.Title>
          <Styled.Note>{t('note_support')}</Styled.Note>
          <Styled.Title>
            <BlueIconComp icon={<MdSupportAgent style={styleIcon} />} />
            {t('about_system')}
          </Styled.Title>
          <Styled.Note>{t('intro_tesco')}</Styled.Note>
          <Styled.Title>
            <BlueIconComp icon={<MdSupportAgent style={styleIcon} />} />
            {t('role_reciver_order')}
          </Styled.Title>
          <Styled.Note>
            {t('warning')}
            <div>{t('suggest_register')}</div>
            <div>{t('suggeest_recharge_online')}</div>
            <div>{t('suggeest_reciver_order')}</div>
            <div>{t('suggeest_fisnish_order')}</div>
            <div>{t('suggeest_withdraw_money')}</div>
          </Styled.Note>
        </Styled.Block>
      </Styled.WarpBox>
    </Styled.Container>
  )
}
export default SupportPage
