import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { getImageFromServer } from 'utils/funcHelper'
import { URL_IMAGE } from 'services/ApiService'
import { formatPrice } from 'utils/ruleForm'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useHistory } from 'react-router-dom'
import { sendOrder } from './api/ApiOrder'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

type Props = { location: Record<any, any> }

const WapperPendingOrder = styled.div`
  background-color: #394354;
  padding: 20px 12px;
  border-radius: 5px;
  .productName {
    font-size: 16px;
    margin-bottom: 10px;

    /* giới hạn số dòng text === 3 */
    display: -webkit-box;
    max-height: 4.8rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 3;
    line-height: 1.6rem;
  }
  .productInfo {
    display: flex;
    gap: 5px;
    .title {
      color: #8f8f8f;
    }
  }
  .productImg {
    margin: 20px 0;
    .img {
      width: 100%;
    }
  }
`

function PendingOder(props: Props) {
  const { location } = props
  const { state } = location
  const history = useHistory()
  const { t } = useTranslation()

  const onPurchaseOrder = async () => {
    try {
      const res = await sendOrder(state.id)
      if (res.data.status === 'Success') {
        history.push({
          pathname: ADMIN_ROUTER_PATH.HISTORY,
          search: '?status=Success',
        })
      } else {
        message.error(res.message)
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  return (
    <>
      <WapperPendingOrder>
        <div className="productName">{state.name_product}</div>
        <div className="productInfo">
          <div className="title">{t('code')}:</div>
          <div className="content">{state.id}</div>
        </div>
        <div className="productInfo">
          <div className="title">{t('time_reciver')}:</div>
          <div className="content">
            {moment(state.time).format('DD/MM/YYYY HH:mm:ss')}
          </div>
        </div>
        <div className="productImg">
          <img
            className="img"
            src={state.image_url}
            alt="product_image"
            crossOrigin="anonymous"
          />
        </div>
        <div className="productInfo">
          <div className="title">{t('price_order')}:</div>
          <div className="content" style={{ color: 'purple', fontWeight: 700 }}>
            VNĐ {formatPrice(state.price_product)}
          </div>
        </div>
        <div className="productInfo">
          <div className="title">{t('profit')}:</div>
          <div className="content" style={{ color: 'green', fontWeight: 700 }}>
            VNĐ {formatPrice(state.commission)}
          </div>
        </div>
        <div className="productInfo">
          <div className="title">{t('total_refund')}:</div>
          <div className="content">
            VNĐ {formatPrice(state.commission + state.price_product)}
          </div>
        </div>
        <div className="productInfo">
          <div className="title">{t('current_balance')}:</div>
          <div className="content">
            VNĐ {formatPrice(state.commission + state.price_product)}
          </div>
        </div>
      </WapperPendingOrder>

      <button
        style={{
          width: '100%',
          marginTop: '20px',
          height: '36px',
          backgroundColor: '#fbd437',
          border: 'none',
          color: 'black',
          fontSize: '16px',
          fontWeight: 'bold',
          borderRadius: '5px',
        }}
        disabled={state.status === 'Pending'}
        onClick={() => {
          if (state.status === 'Pending') {
            return
          } else {
            onPurchaseOrder()
          }
        }}
      >
        {state.status === 'Pending'
          ? t('pending_order')
          : t('order_processing')}
      </button>
    </>
  )
}

export default PendingOder
