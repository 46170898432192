import React from 'react'
import '../style.css'
import { AiOutlineSound } from 'react-icons/ai'

type Props = {
  content?: string
}

const TextAnimate = ({ content }: Props) => {
  return (
    <div className="textAnimate">
      <AiOutlineSound className="icon" />
      <div className="wrapper">
        <p className="target">
          {content || 'Đang cập nhật nội dung thông báo!'}
        </p>
      </div>
    </div>
  )
}

export default TextAnimate
