import { message, Row } from 'antd'
import React from 'react'
import { CrownIcon, CoinIcon, BankIcon } from 'common/components/Icons'
import * as Styled from '../styled'
import history from 'utils/history'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { useAppSelector } from 'redux/store/store'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import { MdNotificationsActive, MdSupportAgent } from 'react-icons/md'
import R from 'assets'
import { useTranslation } from 'react-i18next'

const QuickAction: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <Row justify="space-around" gutter={{ xs: 4, sm: 14, md: 24, lg: 32 }}>
      <Styled.ActionBlock
        onClick={() => {
          // window.open('https://tawk.to/chat')
          window.open('https://s.net.vn/IgYr')
          // history.push(ADMIN_ROUTER_PATH.SUPPORT)
        }}
        span={4}
      >
        <div className="main-action">
          <img src={R.icon.naptien} style={StyleIcon} />
          {t('recharge')}
        </div>
      </Styled.ActionBlock>

      <Styled.ActionBlock
        onClick={() => {
          if (userInfo?.balance == 0) {
            Swal.fire({
              title: 'Thông báo',
              text: 'Số dư tài khoản không đủ để thực hiện thao tác này. Bạn có muốn nạp tiền?',
              icon: 'error',
              showCancelButton: true,
            }).then((result: any) => {
              if (result.isConfirmed) {
                history.push(ADMIN_ROUTER_PATH.RECHARGE_MONEY)
              }
            })
          } else {
            if (userInfo.bank) {
              history.push(ADMIN_ROUTER_PATH.WITHDRAW_MONEY)
            } else {
              message.error('Vui lòng cập nhập thông tin thẻ ngân hàng')
              history.push(ADMIN_ROUTER_PATH.BANK_CARD)
            }
          }
        }}
        span={4}
      >
        <div className="main-action">
          <img src={R.icon.ruttien} style={StyleIcon} />
          {t('withdraw_money')}
        </div>
      </Styled.ActionBlock>

      <Styled.ActionBlock
        onClick={() => {
          history.push(ADMIN_ROUTER_PATH.MEMBER_RANK)
        }}
        span={4}
      >
        <div className="main-action">
          <img src={R.icon.dautu} style={StyleIcon} />
          {t('upgrade')}
        </div>
      </Styled.ActionBlock>

      <Styled.ActionBlock
        onClick={() => {
          window.open('https://s.net.vn/IgYr')
          // history.push(ADMIN_ROUTER_PATH.SUPPORT)
        }}
        span={4}
      >
        <div className="main-action">
          <img src={R.icon.cskh} style={StyleIcon} />
          {t('support')}
        </div>
      </Styled.ActionBlock>

      <Styled.ActionBlock
        onClick={() => {
          // history.push(ADMIN_ROUTER_PATH.SUPPORT)
        }}
        span={4}
      >
        <div className="main-action">
          <img src={R.icon.thongbao} style={StyleIcon} />
          {t('notification')}
        </div>
      </Styled.ActionBlock>
    </Row>
  )
}

const StyleIcon = {
  width: '26px',
  height: '26px',
  marginBottom: '0.3rem',
  // color: '#1890ff',
}
export default QuickAction
