import PageTitle from 'common/components/PageTitle'
import React, { useEffect } from 'react'
import { Button, Form, Input, message } from 'antd'
import * as Styled from '../withdraw_money/styled'
import { REG_PHONE } from 'utils/constants'
import Cookie from 'js-cookie'
import history from 'utils/history'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { requestUpdateOrderAddress } from 'services/api/AccountApi'
import { useAppSelector } from 'redux/store/store'
import { useTranslation } from 'react-i18next'

const DeliveryAddressPage: React.FC = () => {
  const formItemStyle = { style: { marginBottom: 10 } }
  const inputStyle = { style: { height: 40 } }

  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const { t } = useTranslation()
  const replaceAt = (replacement: any) => {
    let str = replacement.split('')
    str = str.map((element: any, index: any) => {
      if (index > str.length - 8) return (element = '*')
      return element
    })
    return str.join('')
  }

  // let addressData: any = Cookie.get('userAddr') || undefined
  let addressData: any = userInfo?.order_address
  let data = addressData ? addressData : { name: '', phone: '', address: '' }
  data = {
    name: data?.name ? data?.name : '',
    phone: data?.phone ? replaceAt(data?.phone) : '',
    address: data?.address ? data?.address : '',
  }
  addressData = data

  const onFinish = async (values: any) => {
    await requestUpdateOrderAddress(values)
    // Cookie.set(
    //   'userAddr',
    //   JSON.stringify({
    //     name: values.name,
    //     phone: values.phone,
    //     address: values.address,
    //   })
    // )

    message.success(t('update_address_finsh'))
    history.push(ADMIN_ROUTER_PATH.PROFILE)
  }

  return (
    <div>
      <Styled.WrapCardCenter>
        <PageTitle title={t('address_reciver')} />
        <Styled.CardForm>
          <span>{t('address_reciver')}</span>
          <Form
            onFinish={onFinish}
            layout={'vertical'}
            initialValues={addressData}
          >
            <Form.Item
              {...formItemStyle}
              name="name"
              label={t('name')}
              rules={[{ required: true, message: t('not_empty') }]}
            >
              <Input
                {...inputStyle}
                disabled={data?.name}
                // prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t('name')}
              />
            </Form.Item>
            <Form.Item
              {...formItemStyle}
              name="phone"
              label={t('phone')}
              rules={[{ message: t('not_empty') }]}
            >
              <Input
                {...inputStyle}
                disabled={data?.phone}
                // prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t('phone')}
              />
            </Form.Item>
            <Form.Item
              {...formItemStyle}
              name="address"
              label={t('address_detail')}
              rules={[{ required: true, message: t('not_empty') }]}
            >
              <Input
                {...inputStyle}
                disabled={data?.address}
                // prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t('address_detail')}
              />
            </Form.Item>
            {!addressData?.name ? (
              <Form.Item
                style={{
                  marginBottom: 0,
                  marginTop: 25,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: '100%',
                    height: 40,
                    fontWeight: 700,
                  }}
                >
                  {t('update')}
                </Button>
              </Form.Item>
            ) : null}
          </Form>
        </Styled.CardForm>
      </Styled.WrapCardCenter>
    </div>
  )
}
export default DeliveryAddressPage
