import R from 'assets'
import i18n from 'assets/i18n/translation'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { useTranslation } from 'react-i18next'
import history from 'utils/history'
import './style.css'
import * as Styled from './styled'

const FooterContainer: React.FC = () => {
  const { t } = useTranslation()
  const MenuFooter = [
    {
      pathname: ADMIN_ROUTER_PATH.HOME,
      title: t('home'),
      icon: <img src={R.icon.trangchu} style={styleIcon} />,
    },
    {
      pathname: ADMIN_ROUTER_PATH.SYSTEM,
      title: t('system'),
      icon: <img src={R.icon.hethong} style={styleIcon} />,
    },
    {
      pathname: ADMIN_ROUTER_PATH.ORDER,
      title: t('transaction'),
      icon: <img src={R.icon.giaodich} style={styleIcon} />,
      special: true,
    },
    {
      pathname: ADMIN_ROUTER_PATH.HISTORY,
      title: t('history'),
      icon: <img src={R.icon.lichsu} style={styleIcon} />,
    },
    // {
    //   pathname: ADMIN_ROUTER_PATH.SUPPORT,
    //   title: 'Hỗ trợ',
    //   icon: <MdSupportAgent style={styleIcon} />,
    // },
    {
      pathname: ADMIN_ROUTER_PATH.PROFILE,
      title: t('account'),
      icon: <img src={R.icon.taikhoan} style={styleIcon} />,
    },
  ]

  return (
    <Styled.FooterBlock>
      <div className="main-footer">
        {MenuFooter?.map((item: any, index: number) =>
          item.special ? (
            <span
              key={index}
              className={`menu-nav ${
                item.pathname === window.location.pathname ? 'menu-active' : ''
              }`}
              id="order"
              onClick={() => {
                history.push(item.pathname)
              }}
            >
              <div className="circle-item">
                {item.icon} <p>{item.title} </p>
              </div>
            </span>
          ) : (
            <span
              key={index}
              className={`menu-nav ${
                item.pathname === window.location.pathname ? 'menu-active' : ''
              }`}
              onClick={() => {
                history.push(item.pathname)
              }}
            >
              {item.icon} <p>{item.title}</p>
            </span>
          )
        )}
      </div>
    </Styled.FooterBlock>
  )
}

const styleIcon = { width: '18px', height: '18px' }
export { FooterContainer }
