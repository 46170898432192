import { Col, Row } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

type Props = {}

const SystemWrapper = styled.div`
  .header {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
  }
  .learn-about {
    background-color: #334358;
    border: none;
    width: 100%;
    margin-top: 10px;
    padding: 3px;
    font-weight: 600;
  }
  .banner {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    .banner-item {
      background-color: #334358;
      width: 220px;
      height: 220px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-radius: 10px;
      .inner-item {
        position: relative;
        width: 100%;
        height: 70%;
        background-color: #1e212c;
        border-radius: 10px;
        .logo {
          position: absolute;
          top: -50%;
          left: 50%;
          transform: translateX(-50%) translateY(50%);
        }
        .content {
          padding: 22% 20%;
          .item {
            text-align: center;
          }
        }
      }
    }
    @media only screen and (max-width: 440px) {
      .banner-item {
        width: 150px;
      }
    }
  }
`

const SystemPage = (props: Props) => {
  const history = useHistory()
  const { t } = useTranslation()

  const banners = [
    {
      img: 'https://api.tiktokshopping.info/uploads/logoebay.jpg',
      title: t('system_introduction'),
    },
    {
      img: 'https://api.tiktokshopping.info/uploads/logoebay.jpg',
      title: t('member'),
    },
  ]

  const CATALOG_LIST = [
    {
      name: t('about_us'),
    },
    {
      name: t('describe'),
    },
    {
      name: t('finance'),
    },
    {
      name: t('corporate_culture'),
    },
    {
      name: t('condition'),
    },
    {
      name: t('Income_form'),
    },
    {
      name: t('partition_group'),
    },
    {
      name: t('personal_policy'),
    },
  ]
  return (
    <SystemWrapper>
      <div className="header">
        <div>{t('system_tesco')}</div>
      </div>
      <button className="learn-about">{t('about_system')}</button>
      <div className="banner">
        {banners.map((item, index) => (
          <div className="banner-item" key={index}>
            <div className="inner-item">
              <div className="logo">
                <img
                  src={item.img}
                  alt="anh"
                  style={{
                    width: 70,
                    height: 70,
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </div>
              <div className="content">
                <div className="item" style={{ height: 50 }}>
                  {item.title}
                </div>
                <div className="item" style={{ fontWeight: 600, fontSize: 18 }}>
                  TIKTOKSHOP
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Row gutter={{ xs: 10, sm: 20, md: 24, lg: 32 }} justify="space-between">
        {CATALOG_LIST.map(item => (
          <Col
            xs={12}
            sm={12}
            xl={6}
            style={{ marginTop: '12px', cursor: 'pointer' }}
            onClick={() => {
              history.push(ADMIN_ROUTER_PATH.SUPPORT)
            }}
          >
            <div
              style={{
                backgroundColor: '#1e212c',
                borderRadius: '8px',
                padding: '20px 10px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {item.name}
            </div>
          </Col>
        ))}
      </Row>
    </SystemWrapper>
  )
}

export default SystemPage
