import { Col, message, Progress, Row } from 'antd'
import R from 'assets'
import { InfoBlock } from 'common/components/InfoBlock'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'redux/store/store'
import { getUserAnalytic } from 'services/api/CommonApi'
import styled from 'styled-components'
import { IsBlockCreateOrder } from 'utils/constants'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import {
  requestGetNotification,
  requestLevelCategory,
  requestLevelDetail,
  requestLevelList,
  requestSetLevelUser,
} from '../home/Api/LevelApi'
import TextAnimate from '../home/components/TextAnimate'
import { requestAnOrder } from './api/ApiOrder'
import * as Styled from './styled'

const styleIcon = { fontSize: '35px', color: 'white' }

const WrapUserIncomeList = styled.div`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.65);
`

const UserIncomeList = styled.div`
  display: flex;
  justify-content: space-around;

  background-color: #495364;

  margin: 6px 0px;
  border-radius: 4px;
  padding: 4px 0px;

  div {
    color: #7e8896;
    font-size: 18px;
  }

  span {
    color: white;
    font-weight: 6500;
    font-size: 20px;

    @media only screen and (max-width: 480px) {
      font-size: 14px;
    }
  }
`

const ProgressStyled = styled(Progress)`
  &&& {
    .ant-progress-text {
      color: white;
      font-weight: 600;
    }
  }
`

const ProfilePage: React.FC = () => {
  const timeout = React.useRef<any>(null)
  const [process, setProcess] = useState<number>(0)

  const [data, setData] = useState<any>({})
  const [dataLevel, setDataLevel] = useState<any>({})
  const [levels, setLevels] = useState<any>([])
  const [activeButton, setActiveButton] = useState<boolean>(true)
  const [analyticDetail, setAnalyticDetail] = useState<any>()
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  let addressData: any = userInfo?.order_address
  const [noti, setNoti] = useState<any>()
  const [language, setLanguage] = useState<any>('')

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (!activeButton) {
      timeout.current = setInterval(() => {
        setProcess(prev => (prev += 1))
      }, 50)
    }
  }, [activeButton])

  useEffect(() => {
    if (process === 100) {
      history.push({
        pathname: ADMIN_ROUTER_PATH.BILL,
        state: {
          own_level: userInfo?.level,
          first_level: dataLevel?.key,
          userInfo,
          analyticDetail,
        },
      })
    }
  }, [process])

  const requestSetLevel = async () => {
    try {
      const res = await requestSetLevelUser()
      if (res) getLevelList(res.data.level)
    } catch (error) {
      console.log('error')
    }
  }

  const getNotification = async () => {
    try {
      const res = await requestGetNotification()
      if (res.data) {
        let language = await localStorage.getItem('language')
        setLanguage(language)
        setTimeout(() => {
          setNoti(res.data[0])
        }, 150)
      }
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  useEffect(() => {
    getNotification()
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get('key')
    if (myParam) {
      getLevelDetail(myParam)
    } else {
      requestSetLevel()
      // getLevelList()
    }
    // setTimeout(() => {
    //   setShowProduct(true)
    // }, 4100)

    return () => {
      timeout.current && clearTimeout(timeout.current)
    }
  }, [])

  useEffect(() => {
    dispatch(getUserInfoAction())
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get('key')

    if (myParam) {
      getLevelCategory(myParam)
    } else {
      if (Object.keys(dataLevel).length) getLevelCategory(dataLevel.key)
    }
  }, [dataLevel])

  useEffect(() => {
    ;(async () => {
      const analyticData = await getUserAnalytic()
      setAnalyticDetail(analyticData.data)
    })()
  }, [])

  const [levelList, setLevelList] = useState<any>([])

  const getLevelList = async (level: any) => {
    try {
      const res = await requestLevelList()
      setLevels(res?.data)
      setLevelList(res.data)

      const ownLevel = level

      let currentLevelData = res.data.find((item: any) => item.key == ownLevel)
      currentLevelData = currentLevelData ? currentLevelData : res.data[0]

      setDataLevel(currentLevelData)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }
  const getLevelDetail = async (myParam: any) => {
    try {
      const res = await requestLevelDetail(myParam)
      setDataLevel(res.data)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getLevelCategory = async (myParam: any) => {
    try {
      const res = await requestLevelCategory(myParam)
      const category = res.data.map((item: any) => {
        return item.name
      })

      setData({ ...dataLevel, category })
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  // const buyProduct = async (key: any, productId: any) => {
  //   try {
  //     await requestAddOrder(key, productId)
  //     history.push(ADMIN_ROUTER_PATH.HISTORY)
  //   } catch (error) {
  //     console.error('Exception ' + error)
  //   }
  // }

  // const renderModal = () => {
  //   return (
  //     <Styled.ModalProduct
  //       title="Chi tiết sản phẩm"
  //       visible={showModal}
  //       footer={null}
  //       onCancel={() => {
  //         setShowModal(false)
  //       }}
  //     >
  //       <div className="wrap-product">
  //         <div className="name-product">{detailProduct.name}</div>
  //         <div className="img-product">
  //           <img
  //             src={getImageFromServer(detailProduct.image)}
  //             alt="product"
  //             crossOrigin="anonymous"
  //           />
  //         </div>
  //         <div className="price-product">
  //           <div className="price-block">
  //             <span>Giá tiền(đ):</span>
  //             <div style={{ color: 'var(--green-1)' }}>
  //               {formatPrice(detailProduct.price)}
  //             </div>
  //           </div>
  //           <div className="price-block">
  //             <span>Tiền nhận(đ):</span>
  //             <div style={{ color: 'var(--orange-1)' }}>
  //               {formatPrice(
  //                 (detailProduct.price * data.commission_percent) / 100
  //               )}
  //             </div>
  //           </div>
  //         </div>
  //         <Styled.BuyBtn
  //           icon={<ShoppingCartOutlined />}
  //           onClick={() => {
  //             buyProduct(data.key, detailProduct._id)
  //           }}
  //         >
  //           Mua hàng
  //         </Styled.BuyBtn>
  //       </div>
  //     </Styled.ModalProduct>
  //   )
  // }

  const handleNumberOrder = () => {
    let a
    levels?.map((item: any) => {
      if (item?.key == userInfo?.level) {
        a = item?.order_quantity_per_day
        return a
      }
      return
    })
    return a || levels[0]?.order_quantity_per_day
  }
  const handleCommissionOrder = () => {
    let a
    levels?.map((item: any) => {
      if (item?.key == userInfo?.level) {
        a = item?.commission_percent
        return a
      }
      return
    })
    return (a || levels[0]?.commission_percent) + '%'
  }

  const getData = async (key: any) => {
    try {
      const res = await requestAnOrder(key)
      setData(res.data)
    } catch (error) {
      history.goBack()
      // message.error('Có lỗi xảy ra')
    }
  }

  return (
    <div style={{ padding: '0 12px' }}>
      <Styled.WhiteBox style={{ marginTop: '2rem' }}>
        {/* <Styled.Title>
          <BlueIconComp icon={<UserIcon style={styleIcon} />} />
          {data.name}
        </Styled.Title> */}
        <div style={{ padding: '0 12px' }}>
          {/* <div style={{ marginTop: '1rem' }}>{data?.category?.join(' | ')}</div>
          <div style={{ fontWeight: 600, marginTop: '1rem' }}>
            Hoa hồng {data.commission_percent}%
          </div> */}

          <Styled.ImageBlock>
            {/* <img alt="gif" src={R.images.img_countdown} /> */}
            <img alt="gif" src={R.images.banner5} />
          </Styled.ImageBlock>
          <TextAnimate
            content={language == 'vi' ? noti?.content : noti?.content_english}
          />

          {/* <Styled.NotificationBlock>
            Chưa khởi động khớp, vui lòng 10:00 - 23:00 tiến hành thao tác
          </Styled.NotificationBlock> */}

          {activeButton ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '1rem 0',
              }}
            >
              <Styled.StartBtn
                type={activeButton ? 'primary' : 'dashed'}
                size="large"
                onClick={() => {
                  if (!addressData) {
                    message.error(t('please_enter_address'))
                    return
                  }
                  if (
                    userInfo?.is_block_create_order ===
                    IsBlockCreateOrder.Blocked
                  ) {
                    message.error(t('congratulations_order'))
                    return
                  } else {
                    setActiveButton(false)
                    // buttonRef.current = setTimeout(() => {
                    //   history.push({
                    //     pathname: ADMIN_ROUTER_PATH.BILL,
                    //     state: {
                    //       own_level: userInfo?.level,
                    //       first_level: dataLevel?.key,
                    //       userInfo,
                    //       analyticDetail,
                    //     },
                    //   })
                    // }, 5000)
                  }
                }}
              >
                {t('receive_order')}
              </Styled.StartBtn>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',

                marginTop: '12px',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                {t('finding_order')}
              </div>
              <ProgressStyled
                strokeColor={{
                  from: '#108ee9',
                  to: '#87d068',
                }}
                percent={process}
                status="active"
              />
            </div>
          )}

          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            justify="space-between"
          >
            <Col xs={12} sm={12} lg={6} style={{ marginTop: '12px' }}>
              <InfoBlock
                title1={t('account_balance')}
                value1={`VNĐ ${formatPrice(
                  Number(Number.parseFloat(userInfo?.balance).toFixed(2))
                )}`}
                title2={t('income_today')}
                value2={`VNĐ ${formatPrice(
                  Number(
                    Number.parseFloat(
                      analyticDetail?.total_commission_today
                    ).toFixed(2)
                  )
                )}`}
              />
            </Col>

            <Col xs={12} sm={12} lg={6} style={{ marginTop: '12px' }}>
              <InfoBlock
                type="featured"
                title1={t('processing_balance')}
                value1={`VNĐ ${formatPrice(
                  Number(Number.parseFloat(userInfo?.keep_money).toFixed(2))
                )}`}
                title2={t('income_yesterday')}
                value2={`VNĐ ${formatPrice(
                  Number(
                    Number.parseFloat(analyticDetail?.total_commission).toFixed(
                      2
                    )
                  )
                )}`}
              />
            </Col>

            <Col xs={12} sm={12} lg={6} style={{ marginTop: '12px' }}>
              <InfoBlock
                title1={t('your_booth')}
                value1={
                  language == 'vi' ? dataLevel?.name : dataLevel?.name_english
                }
                title2={t('level_investment')}
                value2={`VNĐ ${dataLevel?.price}`}
              />
            </Col>

            <Col xs={12} sm={12} lg={6} style={{ marginTop: '12px' }}>
              <InfoBlock
                title1={t('number_order_finish')}
                value1={`${userInfo?.total_order_today}/${handleNumberOrder()}`}
                // value1={`${analyticDetail?.count_order_today}/${50}`}
                title2={t('comission_order')}
                value2={`${handleCommissionOrder()}`}
              />
            </Col>
          </Row>

          <div
            style={{
              backgroundColor: 'gray',
              fontSize: '18px',
              color: 'yellow',
              fontWeight: 600,
              padding: '8px 0px',
              textAlign: 'center',
              borderRadius: '4px',
              margin: '24px 0px 12px',
            }}
          >
            {t('level_investment').toUpperCase()}
          </div>

          <WrapUserIncomeList>
            {levelList.map((item: any) => (
              <UserIncomeList>
                <div>
                  <span style={{ color: '#fff', fontWeight: 600 }}>
                    {language == 'vi' ? item.name : item.name_english}
                  </span>{' '}
                  <span style={{ color: '#03a84e' }}>
                    VNĐ {formatPrice(item.price)}
                  </span>
                </div>

                <div>
                  {t('commission')}:{' '}
                  <span style={{ color: '#03a84e' }}>
                    {item.commission_percent}%
                  </span>
                </div>
              </UserIncomeList>
            ))}
          </WrapUserIncomeList>
        </div>
      </Styled.WhiteBox>
      {/* {renderModal()} */}
      {/* <Styled.WhiteBox style={{ marginTop: '1rem' }}>
        <Styled.Title>
          <BlueIconComp icon={<AchievementsIcon style={styleIcon} />} />
          Thành quả hôm nay
        </Styled.Title>
        <Row style={{ width: '100%' }}>
          <Styled.InfoBlock xs={24} md={12}>
            <p>Số dư tài khoản</p>
            <span>${formatPrice(analyticDetail?.balance)}</span>
          </Styled.InfoBlock>
          <Styled.InfoBlock xs={24} md={12}>
            <p>Số đơn săn được hôm nay</p>
            <span>{analyticDetail?.count_order_today} đơn</span>
          </Styled.InfoBlock>
          <Styled.InfoBlock xs={24} md={12}>
            <p>Hoa hồng hôm nay đã giành được</p>
            <span>${analyticDetail?.total_commission_today.toFixed(2)}</span>
          </Styled.InfoBlock>
          <Styled.InfoBlock xs={24} md={12}>
            <p>Số tiền đóng băng tài khoản</p>
            <span>${formatPrice(userInfo?.frozen_balance)}</span>
          </Styled.InfoBlock>
        </Row>
      </Styled.WhiteBox>
      <Styled.WhiteBox style={{ marginTop: '1rem' }}>
        <Styled.Title>
          <BlueIconComp icon={<LightBulbIcon style={styleIcon} />} />
          Giải mã
        </Styled.Title>
        <Styled.Note>
          Lưu ý: Nâng cấp gian hàng để nhận được nhiều đơn và có tỷ lệ hoa hồng
          cao hơn !
        </Styled.Note>
      </Styled.WhiteBox>
      <Styled.WhiteBox style={{ marginTop: '1rem' }}>
        <Styled.Title>
          <BlueIconComp icon={<InfoIcon style={styleIcon} />} />
          Hướng dẫn
        </Styled.Title>
        <Styled.Note>
          <div>
            1: Cấp bậc hội viên của bạn có thể được so khớp hằng ngày 60 đơn
            nhiệm vụ
          </div>
          <div>
            2: Nếu bạn không hoàn thành đơn hàng đóng băng bạn sẽ không thể tiếp
            tục nhiệm vụ trong ngày
          </div>
        </Styled.Note>
      </Styled.WhiteBox> */}
    </div>
  )
}
export default ProfilePage
