import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Carousel } from 'antd'
import R from 'assets'
import { url } from 'inspector'
import React, { useRef, useState } from 'react'
import { getListBanner } from 'services/api/CommonApi'
import { URL_IMAGE, URL_IMAGE_RELEASE } from 'services/ApiService'
import * as Styled from '../styled'

const contentStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}
type Props = { data: any }

const SlickBanner: React.FC = () => {
  const carouselRef = useRef<any>(null)
  const [listBanner, setListBanner] = useState([])
  const getData = async () => {
    try {
      const res = await getListBanner()
      if (res) {
        setListBanner(res.data)
      }
    } catch (error) {}
  }

  React.useEffect(() => {
    getData()
  }, [])

  return (
    <Styled.WrapSlickBanner>
      <div style={{ margin: 'auto' }}>
        <Styled.ArrowBlock>
          <LeftOutlined
            className="icon-arror"
            style={{ fontSize: '20px' }}
            onClick={() => {
              if (carouselRef.current) {
                carouselRef.current.prev()
              }
            }}
          />
          <RightOutlined
            className="icon-arror"
            style={{ fontSize: '20px' }}
            onClick={() => {
              if (carouselRef.current) {
                carouselRef.current.next()
              }
            }}
          />
        </Styled.ArrowBlock>
        <Carousel autoplay ref={carouselRef}>
          {listBanner?.map((item: any) => (
            <div>
              <img
                crossOrigin="anonymous"
                alt="banner"
                src={URL_IMAGE + item?.image}
                style={contentStyle}
              />
            </div>
          ))}
          {/* <div>
            <img alt="banner" src={R.images.banner1} style={contentStyle} />
          </div>
          <div>
            <img alt="banner" src={R.images.banner2} style={contentStyle} />
          </div>
          <div>
            <img alt="banner" src={R.images.banner3} style={contentStyle} />
          </div>
          <div>
            <img alt="banner" src={R.images.banner4} style={contentStyle} />
          </div>
          <div>
            <img alt="banner" src={R.images.banner5} style={contentStyle} />
          </div> */}
        </Carousel>
        {/* <div className="marquee">
          <div className="gonggao">
            <li className="animate">
              Cảm ơn quý khách hàng đã không ngừng ủng hộ TiKi Mall , TiKi
              Merchant Mall . Sẽ triển khai nhiều Chương Trình ưu đãi nhiều hơn
              nữa . Chúng Tôi mong rằng quý khách hàng sẽ tuân thủ theo quy định
              của Công Ty chúng tôi ! Chân Thành Cảm Ơn .
            </li>
          </div>
        </div> */}
      </div>
    </Styled.WrapSlickBanner>
  )
}
export default SlickBanner
