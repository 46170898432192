import { Button, Form, Input, message, Select } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'redux/store/store'
import { requestUpdateBankInfor } from 'services/api/AccountApi'
import { UserBankNames } from 'utils/constants'
import history from 'utils/history'
import * as Styled from './styled'

const BankCardPage: React.FC = () => {
  const { Option } = Select
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const { t } = useTranslation()
  const onFinish = async (values: any) => {
    const resData = (
      await requestUpdateBankInfor({
        full_name: values.name,
        phone: values.phone,
        bank_number: values.bankNumber,
        bank_name: values.bankName,
      })
    ).data

    if (resData.bank) {
      message.success(t('update_info_card'))
      history.push(ADMIN_ROUTER_PATH.PROFILE)
    }
  }

  const replaceAt = (replacement: any) => {
    let str = replacement.split('')
    str = str.map((element: any, index: any) => {
      if (index > str.length - 4) return (element = '*')
      return element
    })
    console.log('str', str.join(''))

    return str.join('')
  }

  return (
    <Styled.Container>
      <Styled.Title>{t('my_bank_card')}</Styled.Title>

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          name: userInfo?.bank ? userInfo?.bank?.full_name : '',
          bankNumber: userInfo?.bank
            ? replaceAt(userInfo?.bank?.bank_number)
            : '',
          bankName: userInfo?.bank ? userInfo?.bank?.bank_name : '',
          phone: userInfo?.bank ? userInfo?.bank?.phone : '',
        }}
        onFinish={onFinish}
        autoComplete="off"
        style={{ marginTop: '1rem' }}
      >
        <Form.Item
          label={t('name')}
          name="name"
          rules={[{ required: true, message: t('not_empty') }]}
        >
          <Input
            placeholder={t('name')}
            disabled={userInfo?.bank && userInfo?.bank?.full_name}
          />
        </Form.Item>
        <Form.Item
          label={t('phone')}
          name="phone"
          rules={[{ message: t('not_empty') }]}
        >
          <Input
            placeholder={t('phone')}
            disabled={userInfo?.bank && userInfo?.bank?.phone}
          />
        </Form.Item>
        <Form.Item
          label={t('number_card')}
          name="bankNumber"
          rules={[{ required: true, message: t('not_empty') }]}
        >
          <Input
            placeholder={t('number_card')}
            disabled={userInfo?.bank && userInfo?.bank?.bank_number}
          />
        </Form.Item>
        <Form.Item
          name={'bankName'}
          label={t('bank_name')}
          rules={[{ required: true, message: t('not_empty') }]}
        >
          <Select
            disabled={userInfo?.bank && userInfo?.bank?.bank_name}
            showSearch
            placeholder={t('bank_name')}
            optionFilterProp="children"
            onChange={() => {}}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {Object.values(UserBankNames).map(bank => (
              <Option value={bank}>{bank}</Option>
            ))}
          </Select>
        </Form.Item>

        <span style={{ color: 'red' }}>{t('remind')}</span>
        {!userInfo?.bank ? (
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {t('setting_info').toUpperCase()}
            </Button>
          </Form.Item>
        ) : null}
      </Form>
    </Styled.Container>
  )
}
export default BankCardPage
