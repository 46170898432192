import { message, notification } from 'antd'
import R from 'assets'
import { LightBulbIcon } from 'common/components/Icons'
import BlueIconComp from 'common/components/Icons/BlueIconComp'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/store/store'
import { getUserAnalytic } from 'services/api/CommonApi'
import { URL_IMAGE } from 'services/ApiService'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import { requestAnOrder, sendOrder } from './api/ApiOrder'
import * as S from './styled'

const styleIcon = { fontSize: '35px', color: 'white' }

const BillOrder: React.FC = () => {
  const [data, setData] = useState<any>({})
  const location: any = useLocation()
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const [analyticDetail, setAnalyticDetail] = useState<any>()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    if (location.state.own_level) {
      getData(location.state.own_level)
    } else {
      getData(location.state.first_level)
    }
  }, [])

  const getData = async (key: any) => {
    try {
      const res = await requestAnOrder(key)
      setData(res.data)
      dispatch(getUserInfoAction())
    } catch (error) {
      history.goBack()
      // message.error('Có lỗi xảy ra')
    }
  }

  const getAnalyticUser = async () => {
    try {
      const analyticData = await getUserAnalytic()
      if (analyticData) setAnalyticDetail(analyticData?.data)
    } catch (error) {}
  }

  const handleClickBtn = async () => {
    dispatch(getUserInfoAction())
    setTimeout(async () => {
      try {
        const res = await sendOrder(data?._id)
        if (res.data?.status === 'Success' || res.data?.status === 'Pending') {
          notification.success({
            message: t('order'),
            description: t('send_order_success'),
          })
          if (userInfo?.total_order_today + 2 == userInfo?.last_order?.order) {
            getData(location.state.own_level)
            getAnalyticUser()
            setTimeout(() => {
              if (
                analyticDetail?.count_order_today == userInfo?.last_order?.order
              ) {
                return history.push(ADMIN_ROUTER_PATH.ORDER)
              }
            }, 200)
            return
          }
          history.push(ADMIN_ROUTER_PATH.ORDER)
        }
      } catch (error) {
        // message.error('Không đủ số dư, vui lòng nạp tiền vào tài khoản')
      }
    }, 200)
  }

  return (
    <S.WrapContainer>
      <S.BillContainer>
        <S.Title>
          <BlueIconComp icon={<LightBulbIcon style={styleIcon} />} />
          {t('order_unit')}
        </S.Title>
        <div style={{ marginTop: '1rem' }}>
          {t('time_reciver')}：
          {data?.created_at &&
            moment(data?.created_at).format('DD/MM/YYYY HH:mm:ss')}
        </div>
        <div>
          {t('code')}：{data?._id}
        </div>
        <b>{data?.meta?.level.commission_percent}%</b>
        <S.ImageBlock>
          <img
            alt="gif"
            src={URL_IMAGE + data?.product?.image}
            crossOrigin="anonymous"
          />
        </S.ImageBlock>
        <div className="info">
          {t('total_order')}:
          <span className="content">VNĐ {formatPrice(data?.meta?.value)}</span>
        </div>
        <div className="info">
          {t('commission')}:
          <span className="content">
            VNĐ {formatPrice(data?.meta?.commission)}
          </span>
        </div>
        <div className="info">
          {t('money_refund')}:
          <span className="content">
            {' '}
            VNĐ {formatPrice(data?.meta?.value + data?.meta?.commission)}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <S.StartBtn
            type="primary"
            size="large"
            onClick={() => {
              handleClickBtn()
            }}
          >
            {t('send_order')}
          </S.StartBtn>
        </div>
      </S.BillContainer>
    </S.WrapContainer>
  )
}
export default BillOrder
