import { Col, Row } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React, { useEffect, useState } from 'react'
import { formatPrice } from 'utils/ruleForm'
import history from 'utils/history'
import * as Styled from './styled'
import moment from 'moment'
import { BiUserCircle } from 'react-icons/bi'
import { requestMyGroup } from './api/MyGroupApi'
import { useTranslation } from 'react-i18next'

const MyGroupPage: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const myParam = urlParams.get('tabs')
  const [data, setData] = useState<any>({ totalData: {}, dataUsers: [] })
  const { t } = useTranslation()
  useEffect(() => {
    getData()
  }, [myParam])

  const getData = async () => {
    try {
      const res = await requestMyGroup(myParam)
      setData(res.data)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const styleCol = { border: '1px orange solid', padding: '12px' }
  return (
    <Styled.Container>
      <Styled.Title>{t('my_room')}</Styled.Title>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Row style={{ marginTop: '1rem', width: '50%' }} justify="space-around">
          <Col md={12} style={styleCol}>
            <div>
              <div>{t('group_balance')}</div>
              <div>{formatPrice(data.totalData?.total_balance)}</div>
            </div>
          </Col>
          <Col md={12} style={styleCol}>
            <div>
              <div>{t('commissions_from_subordinates')}</div>
              <div>{formatPrice(data.totalData?.commission)}</div>
            </div>
          </Col>
          <Col md={12} style={styleCol}>
            <div>
              <div>{t('total_recharge')}</div>
              <div>{formatPrice(data.totalData?.total_topUp)}</div>
            </div>
          </Col>
          <Col md={12} style={styleCol}>
            <div>
              <div>{t('total_withdraw')}</div>
              <div>{formatPrice(data.totalData?.total_CashOut)}</div>
            </div>
          </Col>
          <Col md={12} style={styleCol}>
            <div>
              <div>{t('commission_group')}</div>
              <div>{formatPrice(data.totalData?.order_commission)}</div>
            </div>
          </Col>
          <Col md={12} style={styleCol}>
            <div>
              <div>{t('number_member_group')}</div>
              <div>{formatPrice(data.totalData?.total_customer)}</div>
            </div>
          </Col>
        </Row>
      </div>

      <Styled.ContainerTabs>
        <Styled.HeaderTabs>
          <Col
            span={8}
            className={`title-tabs ${
              myParam === 'level1' || !myParam ? 'active' : ''
            }`}
            onClick={() => {
              history.push({
                pathname: ADMIN_ROUTER_PATH.MY_GROUP,
                search: '?tabs=level_1',
              })
            }}
          >
            {t('level')} 1
          </Col>
          <Col
            span={8}
            className={`title-tabs ${myParam === 'level2' ? 'active' : ''}`}
            onClick={() => {
              history.push({
                pathname: ADMIN_ROUTER_PATH.MY_GROUP,
                search: '?tabs=level_2',
              })
            }}
          >
            {t('level')} 2
          </Col>
          <Col
            span={8}
            className={`title-tabs ${myParam === 'level3' ? 'active' : ''}`}
            onClick={() => {
              history.push({
                pathname: ADMIN_ROUTER_PATH.MY_GROUP,
                search: '?tabs=level_3',
              })
            }}
          >
            {t('level')} 3
          </Col>
        </Styled.HeaderTabs>
      </Styled.ContainerTabs>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '80%' }}>
          {data?.dataUsers.length ? (
            <>
              {data?.dataUsers.map((item: any) => (
                <Row
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '12px',
                    marginTop: '8px',
                    padding: '12px',
                  }}
                >
                  <Col
                    span={2}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <BiUserCircle style={{ fontSize: '30px' }} />
                  </Col>
                  <Col span={11}>
                    <div>
                      {t('full_name')}: {item.name}
                    </div>
                    <div style={{ color: 'orange' }}>
                      {t('recharge')}: {formatPrice(item.topUp)}
                    </div>
                    <div style={{ color: 'orange' }}>
                      {t('withdraw_money')}: {formatPrice(item.CashOut)}
                    </div>
                  </Col>
                  <Col span={11}>
                    <div>
                      {t('phone')}: {item.phone}
                    </div>
                    <div>
                      {t('time_register')}:{' '}
                      {moment(item.create_at).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            <Styled.Empty>{t('page_empty')}</Styled.Empty>
          )}
        </div>
      </div>
    </Styled.Container>
  )
}
export default MyGroupPage
